<template>
  <div>
    <img class="screenSize" :src="`/Images/Warhall_login_banner.png`" />
    <form id="login">
      <label>Username or Email</label>
      <input type="text" v-model="emailInput" style="font-family: 'Arial'" @blur="checkUsername"/>
      <label>Password</label>
      <input type="password" v-model="password" style="font-family: 'Arial'" v-on:click="feedback = ''" />
      <button @click.prevent="login">Log in</button>
      <label class="feedback"> {{ feedback }} </label>
      <router-link style="float: left" :to="{ name: 'signup' }"> Sign up </router-link>
      <router-link style="float: right" :to="{ name: 'forgotpassword' }"> Forgot password? </router-link>
    </form>
  </div>
</template>

<script>
import firebase from "firebase/app";
import { loadUsername } from "@/assets/js/common.js";
require("firebase/auth");

export default {
  name: "App",
  data() {
    return {
      emailInput: null,
      email: null,
      password: null,
      feedback: "",
    };
  },

  // beforeRouteEnter(to, from, next) {
  //   next((vm) => {
  //     console.log("BEFOREREOUTEENTER LOGIN: " + vm.$store.state.email);
  //     if (vm.$store.state.email != null) {
  //       console.log;
  //       vm.$router.push({ name: "userhome" });
  //     }
  //   });
  // },

  methods: {
    async checkUsername() {
      // Regular expression to check for a valid email format
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      let isEmail = emailRegex.test(this.emailInput);

      if (!isEmail)
      {
        this.getEmailFromUsername();
      }
      else
      {
        this.email = this.emailInput;
      }
    },

    async getEmailFromUsername() {
      try {
        let dbResponse = await fetch(`/GetEmail/` + this.emailInput, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          credentials: "include",
        });
        let response = await dbResponse.json();
        if (response.error === 0) {
          this.email = response.result;
        } if (response.error === 1) {
          this.feedback = response.result;
          this.email = "";
        } 
      } catch (err) {
        console.log("Error on user credentials");
      }
    },

    async login() {
      let idToken;
      let auth;
      try {
        const login = this.email;
        const password = this.password;
        auth = await firebase.auth().signInWithEmailAndPassword(login, password);
        idToken = await auth.user.getIdToken();
        await fetch(`/sessionLogin`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ idToken }),
          credentials: "include",
        });
      } catch {
        this.feedback = "Incorrect password. Username and password are Caps sensitive.";
        this.$store.commit("setLogin", null);
        this.$store.commit("setID", null);
        this.password = null;
        return;
      }

      if (await loadUsername(this.$store, this.$router)) {
        this.$store.commit("setLogin", this.email);
        this.$store.commit("setID", auth.user.uid);
        this.email = null;
        this.password = null;
        this.$router.push({ name: "userhome" });
      }
    },
  },
};
</script>

<style scoped>
.feedback {
  margin: 20px;
  color: red;
  text-align: center;
}

a {
  padding-top: 10px;
  text-align: center;
}

.signup {
  left: 0;
}

.forgotpassword {
  text-align: right;
}
</style>
