<template>
  <div class="content-section">
    <h2 class="section-header">CART</h2>
    <div class="cart-row">
      <span class="cart-item cart-header cart-column">ITEM</span>
      <span class="cart-price cart-header cart-column">PRICE</span>
    </div>
    <div class="cart-items">
      <cartitem v-for="item in items" :key="item.ID" :item="item" />
    </div>
    <div class="cart-total">
      <strong class="cart-total-title">Total</strong>
      <span class="cart-total-price">{{ total }} €</span>
    </div>
    <button
      :disabled="items == null || items.length == 0"
      @click="purchase"
      class="btn btn-primary btn-purchase"
      type="button"
      >PURCHASE</button
    >
     <button
      :disabled="items == null || items.length == 0"
      @click="paypal"
      class="btn btn-primary btn-purchase paypal"
      type="button"
      >PayPal</button
    >
  </div>
</template>

<script>
import CartItem from "@/components/store/cartitem.vue";
export default {
  components: {
    cartitem: CartItem,
  },
  props: ["items"],

  computed: {
    total() {
      let total = 0;
      for (let elt of this.items) {
        total += elt.item.Coste;
      }
      return total / 100;
    },
  },

  methods: {
    purchase() {
      this.$emit("purchase", this.total);
    },

    paypal() {
      this.$router.push({ name: "paypal" });
    },
  },
};
</script>

<style scoped>
.paypal {
  font-size: 16px; /* Font size (optional) */
}

.cart-header {
  font-weight: bold;
  font-size: 1.25em;
  color: white;
}

.cart-column {
  display: flex;
  align-items: center;
  border-bottom: 1px solid black;
  margin-right: 1.5em;
  padding-bottom: 10px;
  margin-top: 10px;
}

.cart-row {
  display: flex;
}

.cart-item {
  width: 45%;
}

.cart-price {
  width: 20%;
  font-size: 1.2em;
  color: white;
}

/* .cart-quantity {
  width: 35%;
} */

.cart-item-title {
  color: white;
  margin-left: 0.5em;
  font-size: 1.2em;
}

.cart-item-image {
  width: 75px;
  height: auto;
  border-radius: 10px;
}

/* .cart-quantity-input {
  height: 34px;
  width: 50px;
  border-radius: 5px;
  border: 1px solid #56ccf2;
  background-color: #eee;
  color: white;
  padding: 0;
  text-align: center;
  font-size: 1.2em;
  margin-right: 25px;
} */

.cart-row:last-child {
  border-bottom: 1px solid black;
}

.cart-row:last-child .cart-column {
  border: none;
}

.cart-total {
  text-align: end;
  margin-top: 10px;
  margin-right: 10px;
}

.cart-total-title {
  font-weight: bold;
  font-size: 1.5em;
  color: white;
  margin-right: 20px;
}

.cart-total-price {
  color: white;
  font-size: 1.1em;
}
</style>
