<template>
  <div class="content">
    <form id="login">
      <!-- <label>Current password</label>
          <input type="password" v-model="passwordOld" /> -->
      <label>New password</label>
      <input type="password" v-model="passwordNew" style="font-family: 'Arial'" />
      <label>Repeat new password</label>
      <input type="password" v-model="passwordNew2" style="font-family: 'Arial'" />
      <button @click.prevent="changepassword">Change password</button>
      <label class="feedback">{{ feedback }}</label>
      <router-link :to="{ name: 'userhome' }"> Back </router-link>
    </form>
  </div>
</template>

<script>
import firebase from "firebase/app";
require("firebase/auth");

export default {
  name: "App",
  data() {
    return {
      passwordNew: null,
      passwordNew2: null,
      feedback: null,
    };
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$store.state.email == null) {
        vm.$router.push({ name: "login" });
      }
    });
  },

  methods: {
    async changepassword() {
      this.feedback = "";
      if (this.passwordNew.length < 6) {
        this.feedback = "Passwords must be more than 6 characters";
        return;
      }
      if (this.passwordNew != this.passwordNew2) {
        this.feedback = "Passwords are not equal";
        return;
      }

      try {
        const user = firebase.auth().currentUser;
        const newPassword = this.passwordNew;
        await user.updatePassword(newPassword);
        alert("Password changed successfully.");
        this.$router.push({ name: "userhome" });
      } catch (err) {
        console.log("Error while setting new password: " + err);
      }
    },
  },
};
</script>

<style scoped></style>
