<template>
  <form id="login">
    <!-- <label>Current password</label>
          <input type="password" v-model="passwordOld" /> -->
    <label>Warhall code</label>
    <input type="text" v-model="code" />
    <button @click.prevent="redeemcode">Redeem now</button>
    <label class="feedback">{{ feedback }}</label>
    <label class="feedbackOK">{{ feedbackOK }}</label>
    <router-link :to="{ name: 'userhome' }"> Back </router-link>
  </form>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      code: null,
      feedback: null,
      feedbackOK: null,
    };
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$store.state.email == null) {
        vm.$router.push({ name: "login" });
      }
      // Scroll to the top of the page
      window.scrollTo(0, 0);
    });
  },

  methods: {
    async redeemcode() {
      this.feedback = "";
      this.feedbackOK = "";
      try {
        let dbResponse = await fetch(`/ReedemCode/` + this.code, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          credentials: "include",
        });
        let response = await dbResponse.json();
        if (response.error === 0) {
          this.feedbackOK = response.result;
        } else if (response.error === 2) {
          this.feedbackOK = "Dicount code OK!";
          this.$router.push({ name: 'store', query: { codeRedeemed: response.result } });
        } else {
          this.feedback = response.result;
        }
      } catch (err) {
        this.feedback = err;
      }
    },
  },
};
</script>

<style scoped></style>
