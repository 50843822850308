import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import LoadScript from "vue-plugin-load-script";
import VuejsDialog from "vuejs-dialog";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

Vue.use(LoadScript);

// include the default style
import "@/assets/css/alert.css";

// Tell Vue to install the plugin.
Vue.use(VuejsDialog);

window.alert = function (text) {
  Vue.dialog.alert(
    text,
    {
      okText: "OK",
    },
    {
      html: true,
    }
  );
};
