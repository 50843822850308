<template>
  <div>
    <div class="content">
      <form id="login">
        <label>Player Name</label>
        <input type="text" v-model="username" style="font-family: 'Arial'" />
        <label>Email</label>
        <input type="text" v-model="email" style="font-family: 'Arial'" />
        <label>Password</label>
        <input type="password" v-model="password" style="font-family: 'Arial'" />
        <label>Repeat Password</label>
        <input type="password" v-model="password2" style="font-family: 'Arial'" />
        <button @click.prevent="register">Sign up</button>
        <label class="feedback">{{ feedback }}</label>
        <router-link :to="{ name: 'login' }"> Back </router-link>
      </form>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import { loadUsername } from "@/assets/js/common.js";
require("firebase/auth");

export default {
  name: "App",
  data() {
    return {
      email: null,
      username: null,
      password: null,
      password2: null,
      feedback: null,
    };
  },

  // beforeRouteEnter(to, from, next) {
  //   next((vm) => {
  //     if (vm.$store.state.email != null) {
  //       vm.$router.push({ name: "userhome" });
  //     }
  //   });
  // },

  methods: {
    async register() {
      this.feedback = "";
      if (this.email.length < 4 || !this.email.includes("@") || !this.email.includes(".")) {
        this.feedback = "Please provide a vaild email";
        return;
      }
      if (this.password.length < 6) {
        this.feedback = "Passwords must be more than 6 characters";
        return;
      }
      if (this.password != this.password2) {
        this.feedback = "Passwords are not equal";
        return;
      }
      if (!/^[a-zA-Z0-9_]+$/.test(this.username)) {
        this.feedback = "User name can just be letters, numbers or underscore.";
        return;
      }
      if (this.username.Length < 2 || this.username.Length > 20) {
        this.feedback = "User name from 2 to 20 characters long.";
        return;
      }

      try {
        // Check if user name is used
        let respUserExists = await fetch(`/UserExists/` + this.username, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });
        let responseUserExists = await respUserExists.json();

        if (responseUserExists.error != 0) {
          throw "User name exist. Please choose a different one.";
        }

        let userCredential = await firebase.auth().createUserWithEmailAndPassword(this.email, this.password);
        if (userCredential.user.uid === null && userCredential.user.uid === undefined) {
          throw "Email is already in use.";
        }

        // Send verification email
        firebase.auth().currentUser.sendEmailVerification();

        this.feedback = "";
        let email = this.email;
        let userName = this.username;
        let user = userCredential.user.uid;
        let dbResponse = await fetch(`/AddUser/` + userName + `/` + email, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ user }),
          credentials: "include",
        });

        let response = await dbResponse.json();
        if (response.error === 0) {
          alert("Welcome to Warhall! Please check your mail to verify your account.");
          this.login();
        } else {
          throw response.result;
        }
      } catch (err) {
        this.feedback = err;
      }
    },

    async login() {
      let idToken;
      let auth;
      try {
        const login = this.email;
        const password = this.password;
        auth = await firebase.auth().signInWithEmailAndPassword(login, password);
        idToken = await auth.user.getIdToken();
        await fetch(`/sessionLogin`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ idToken }),
          credentials: "include",
        });
      } catch {
        this.feedback = "Incorrect password. Username and password are Caps sensitive.";
        this.$store.commit("setLogin", null);
        this.$store.commit("setID", null);
        this.password = null;
        return;
      }

      if (await loadUsername(this.$store, this.$router)) {
        this.$store.commit("setLogin", this.email);
        this.$store.commit("setID", auth.user.uid);
        this.email = null;
        this.password = null;
        this.$router.push({ name: "userhome" });
      }
    },
  },
};
</script>

<style scoped></style>
