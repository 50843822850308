<template>
  <div>
    <img :src="`/Images/Warhall_directstore_banner.png`" />
    <!-- Display a payment form -->
    <div class="centerDiv">
      <form id="payment-form" class="centerDiv">
        <div id="payment-element">
          <!--Stripe.js injects the Payment Element-->
        </div>
        <button :disabled="isDisabledButton" @click="handleSubmit" type="button">
          <div class="spinner" :class="{ hidden: !loading }" id="spinner"></div>
          <span id="button-text">Pay now</span>
        </button>
        <div id="payment-message" class="hidden"></div>
      </form>
    </div>
    <div class="centerDiv">
      <router-link :to="{ name: 'store' }"> See more </router-link>
    </div>
  </div>
</template>

<script>
import { responseErrorHandling } from "@/assets/js/common.js";
import Vue from "vue";

export default {
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.state.appPadding = false;
      if (vm.$store.state.email == null) {
        vm.$router.push({ name: "login" });
      }
      vm.loadDatafromDB();
    });
  },

  beforeRouteLeave(to, from, next) {
    this.$store.state.appPadding = true;
    next();
  },

  data() {
    return {
      data: [],
      elements: null,
      stripe: null,
      isDisabledButton: false,
      loading: false,
      cart: null,
      // apipk:
      //    "pk_test_51JAAccLFcsfAUf77I1AXSJ0pjYUu5268xODBnE9mvM3pFep8CTNzUSNsbY9XNYsQZUEOSGVKRwThmPnqEbCSbvmw00ezdDcQhd",
      apipk:
       "pk_live_51JAAccLFcsfAUf77L67NcgwiULWzXR1ytaJPTxKYeAbuk0bs1zNFUrd5fcZbEwZBiVfwfajDgPdYgtRJ2eITGCET00btxBLMga",
    };
  },

  computed: {
    serverAddress() {
      return this.$store.state.serverAddress;
    }
  },

  async mounted() {
    await this.initStripe();
  },

  methods: {
    async loadDatafromDB() {
      let res = await fetch(`/GetActiveProductsFullInfo`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      let response = await res.json();
      responseErrorHandling(response, this.$store, this.$router);

      if (response.error === 0) {
        this.data = response.result;
      }

      this.createOneMonthItem();
    },

    async createOneMonthItem() {
      // Create a cart with just a month subscription
      let sections = this.data["sections"];
      let subscriptions = sections["0"];
      let items = subscriptions["items"];
      let oneMonthSub = items["0"];

      this.cart = [];
      this.cart.push({
        quantity: 1,
        item: oneMonthSub,
      });

      this.setLoading(true);
      await this.initCheckout();
      this.setLoading(false);
    },

    async initStripe() {
      await Vue.loadScript("https://js.stripe.com/v3/");
      // eslint-disable-next-line no-undef
      this.stripe = new Stripe(this.apipk);
    },

    async initCheckout() {
      // Disable the button until we have Stripe set up on the page
      let res = await fetch("/create-payment-intent", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ items: this.cart }),
      });

      const { clientSecret } = await res.json();

      const appearance = {
        theme: "stripe",
      };

      this.elements = this.stripe.elements({ appearance, clientSecret });

      const paymentElement = this.elements.create("payment");
      paymentElement.mount("#payment-element");
    },

    async handleSubmit(e) {
      e.preventDefault();
      this.setLoading(true);
      let stripe = this.stripe;
      let elements = this.elements;

      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: this.serverAddress + "/purchasecomplete",
        },
      });

      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error.type === "card_error" || error.type === "validation_error") {
        this.showMessage(error.message);
      } else {
        this.showMessage("An unexpected error occured.");
      }

      this.setLoading(false);
    },

    // Fetches the payment intent status after payment submission
    async checkStatus() {
      const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");

      if (!clientSecret) {
        return;
      }

      const { paymentIntent } = await this.stripe.retrievePaymentIntent(clientSecret);

      switch (paymentIntent.status) {
        case "succeeded":
          this.showMessage("Payment succeeded!");
          break;
        case "processing":
          this.showMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          this.showMessage("Your payment was not successful, please try again.");
          break;
        default:
          this.showMessage("Something went wrong.");
          break;
      }
    },

    /* ------- UI helpers ------- */

    showMessage(messageText) {
      const messageContainer = document.querySelector("#payment-message");

      messageContainer.classList.remove("hidden");
      messageContainer.textContent = messageText;

      setTimeout(function () {
        messageContainer.classList.add("hidden");
        messageText.textContent = "";
      }, 4000);
    },

    // Show a spinner on payment submission
    setLoading(isLoading) {
      this.loading = isLoading;
    },
  },
};
</script>

<style scoped>
/* Variables */
* {
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100vh;
  width: 100vw;
}

form {
  width: 30vw;
  min-width: 500px;
  align-self: center;
  border-radius: 7px;
  padding: 40px;
}

.hidden {
  display: none;
}

#email {
  border-radius: 6px;
  margin-bottom: 16px;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  font-size: 16px;
  width: 100%;
  background: white;
  box-sizing: border-box;
}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
button {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}
button:hover {
  filter: contrast(115%);
}
button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}
.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}
.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}
.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
    min-width: initial;
  }
}

.centerDiv {
  text-align: center;
  width: 70%;
  margin: 0 auto;
}

#payment-form {
  background-color: whitesmoke;
}
</style>
