<template>
  <div>
    <div class="horizontal-layout">
      <input v-if=isGift class="inputbox" v-model="username" placeholder="Gift receiver username" 
            @blur="checkUsername" @focus="startEdit">
      <input v-else class="inputbox" v-model="username" placeholder="Team member username" 
            @blur="checkUsername" @focus="startEdit">
      <div v-if="showStatus">
        <!-- Check Image -->
        <img v-if="status" src="/Images/Ok.png" alt="Check" />

        <!-- None Image -->
        <img v-else src="/Images/Fail.png" alt="None" />
      </div>
    </div>
  </div>
</template>

<script>
import { responseErrorHandling } from "@/assets/js/common.js";

export default {
  data() {
    return {
      username: '',
      status: false,
      showStatus: false,
    };
  },

  props: {
    itemID: null,
    isGift: false,
  },

  mounted() {
    
  },

  computed: {
  },

  methods: {
    async checkUsername() {
      if (this.username == null || this.username == '') { return; }

      let res;
      if (this.itemID != null)
      {
        res = await fetch(`/CheckUsernameAndProduct/` + this.username + '/' + this.itemID, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          credentials: "include",
        });
      }
      else
      {
        res = await fetch(`/CheckUsername/` + this.username, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          credentials: "include",
        });
      }

      let response = await res.json();
      
      if (res.ok) {
        this.showStatus = true;
        this.status = response;
        if (this.status)
        {
          this.$emit('userExists', this.username);
        }
        else
        {
          this.$emit('userExists', '');
      }
      }
    },

    startEdit()
    {
      this.showStatus = false;
    }
  },
};
</script>

<style scoped>
.inputbox {
  width: 250px;
  margin-right: 10px;
  text-align: center;
  font-family: 'Arial';
}
.horizontal-layout {
  display: flex; /* Use flexbox to create a horizontal layout */
  justify-content: center; /* Center elements horizontally */
}
</style>
