<template>
  <div class="center-container">

    <div class="child">
      <h1>Pay Warhall with PayPal</h1>
    </div>

    <div class="child"> 
      <h2>PayPal prices</h2>
    </div>

    <div class="child">  
      <ul>
        <li>12 Months Warhall Pro 87 euros</li>
        <li>6 Months Warhall Pro 51 euros</li>
        <li>3 Months Warhall Pro 31 euros</li>
        <li>1 Month Warhall Pro 12 euros</li>
      </ul>
    </div>
    
    <div class="child"> 
      <h2>Two ways to pay</h2>
    </div>

    <div class="child">  
      <ul>
        <li>Sending payment to: <span class="blue-text">info@warhall.eu</span></li>
        <li>Using QR code below</li>
      </ul>
    </div>
    <h2>Add your Warhall <span class="red-text">username</span> as <span class="red-text">note</span></h2>
    (Account activation can take up to 48hs)
    <div class="child center-container">
      <img class="qrcode" :src="`/Images/PayPalQR.png`"/>
    </div>

  </div>
</template>

<script>
export default {
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      window.scrollTo({ top: 0, behavior: "instant" });
    });
  },
};
</script>

<style scoped>
/* Centering container */
.center-container {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center children horizontally */
}

/* Child elements (example) */
.child {
}

.blue-text {
  color: #09a2e3;
}

.red-text {
  color: #e30909;
}

.qrcode {
  margin: 20px;
  width: 50%; /* 50% of the parent container's width */
  height: auto; /* Maintain the image's aspect ratio */
}
</style>