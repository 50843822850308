<template>
  <div>
    <section class="content-section">
      <h2 :id="section.name" class="section-header">{{ section.name }}</h2>
      <div class="shop-items">
        <storeitem v-for="item in section.items.slice().reverse()" :key="item.ID" :item="item"> </storeitem>
      </div>
    </section>
  </div>
</template>

<script>
import StoreItem from "@/components/store/item.vue";

export default {
  components: {
    storeitem: StoreItem,
  },

  props: ["section"],
};
</script>
<style scoped>
.shop-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
</style>
