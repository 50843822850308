export async function loadUsername(store, router) {
  let res = await fetch(`/GetUsername`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  let response = await res.json();

  responseErrorHandling(response, store, router);

  if (response.error === 0) {
    store.commit("setUsername", response.result[0].Nombre);
    let items = await loadUserOwnedItems(store, router);
    let pro = await loadUserPro(store, router);
    if (items && pro) {
      return true;
    }
  }
  return false;
}

export async function loadUserPro(store, router) {
  let res = await fetch(`/GetUserSubscriptionEndDate`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  let response = await res.json();

  responseErrorHandling(response, store, router);

  if (response.error === 0) {
    //Check if date is earlier
    let sqlDate = response.result[0]["Fin"];
    let proStatus = null;
    if (sqlDate != null) {
      var todayDate = new Date();

      let year = sqlDate.substring(0, 4);
      let month = sqlDate.substring(5, 7);
      let day = sqlDate.substring(8, 10);
      let pulledDate = new Date(year, month, day);

      if (pulledDate > todayDate) {
        proStatus = formatSQLDate(response.result[0]["Fin"]);
      }
    }
    store.commit("setProStatus", proStatus);
    return true;
  }
  return false;
}

export async function loadUserOwnedItems(store, router) {
  let res = await fetch(`/GetAllUserProducts`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  let response = await res.json();

  responseErrorHandling(response, store, router);

  if (response.error === 0) {
    let dlcs = [];

    //Divide all element sinto subscriptions an buyables
    response.result.forEach((element) => {
      if (element.Tipo == "1" || element.Tipo == "4") {
        //TODO this tight now pushed all subscriptions, we should push just the active ones
        // or do the accounting of added time hre
        //console.log(element.Nombre);
        //subscriptions.push(element);
      } else {
        dlcs.push(element.Nombre);
      }
    });
    store.commit("setOwnedDLCS", dlcs);
    return true;
  }
  return false;
}

export function formatSQLDate(sqlDate) {
  try {
    let dateAndTime = sqlDate.split("T");
    let date = dateAndTime[0];
    let splittedDate = date.split("-");
    let formattedDate = splittedDate[2] + "-" + splittedDate[1] + "-" + splittedDate[0];
    return formattedDate;
  } catch {
    return null;
  }
}

export function responseErrorHandling(response, store, router) {
  if (response.error !== 0) {
    store.commit("setLogin", null);
    if (response.error === 1) {
      router.push("/");
    } else {
      alert("An error occurred: " + response.error + " " + response.result);
    }
  }
}
