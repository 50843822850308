<template>
  <div>
    <div class="center"> <h1>{{ message }}</h1> </div>
    <div class="center">
      <router-link :to="{ name: 'userhome' }"> Back home </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      email: null,
      username: null,
      message: "",
    };
  },

  created() {
    if (this.$route.query.result == "ok")
    {
      this.message = "Device registered successfully!";
    } else if (this.$route.query.result == "expired")
    {
      this.message = "Device verification failed: expired link.";
    } else if (this.$route.query.result == "invalid")
    {
      this.message = "Device verification failed: invalid link.";
    }
    else
    {
      this.message = "Device verification error. Please contact developer.";
    }
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$store.state.email == null) {
        vm.$router.push({ name: "login" });
      } else {
        vm.email = vm.$store.state.email;
      }
    });
  },
};

</script>

<style scoped>
.center {
  text-align: center;
  vertical-align: middle;
  line-height: 12;
  padding: 2rem;
}
</style>
