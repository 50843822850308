<template>
  <div class="purchases">
    <button :disabled="items == null || items.length == 0" @click.prevent="showPurchases"> My Purchases </button>
    <div v-show="!hidden">
      <table border="1">
        <thead>
          <tr>
            <th>Buy Date</th>
            <th>Item</th>
            <th>Cost</th>
            <th>Start Date</th>
            <th>Expiry Date</th>
            <th>Download</th>
          </tr>
        </thead>
        <tbody id="example-1">
          <tr v-for="item in items" :key="item.fruit">
            <td>{{ item.BuyDate }}</td>
            <td v-if="item.ProductType == 4" @click="expand">
              <a class="clickable">{{ item.ProductName }}</a>
              <div v-if="expanded">
                <span v-for="(receiver, index) in item.Receivers" :key="index">
                  {{ receiver }}
                  <br />
                </span>
              </div>
            </td>
            <td v-else>{{ item.ProductName }} </td>
            <td>{{ item.ProductCost / 100 }} €</td>
            <td>{{ item.ActivationDate }}</td>
            <td>{{ item.EndDate }}</td>
            <td>
              <template v-if="item.Link != null">
                <a :href="serverAddress + '/DownloadSTL/' + item.IdProduct">
                  <img src="/Images/DownloadIcon.png" title="Download"
                /></a>
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { responseErrorHandling } from "@/assets/js/common.js";

export default {
  data() {
    return {
      hidden: true,
      items: null,
      expanded: false,
    };
  },

  mounted() {
    this.getPurchaseHistory();
  },

  computed: {
    serverAddress() {
      return this.$store.state.serverAddress
    }
  },

  methods: {
    async getDownloadLink(productID) {
      await fetch(`/DownloadSTL/` + productID, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
    },

    async getPurchaseHistory() {
      let res = await fetch(`/GetPurchaseHistory`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      let response = await res.json();

      responseErrorHandling(response, this.$store, this.$router);

      if (response.error != 0) {
        return;
      }

      this.items = response.result;
      for (let i = 0; i < this.items.length; i++) {
        let item = this.items[i];
        item.BuyDate = this.formatSQLDateToHuman(item.BuyDate);
        if (item.ActivationDate != null) {
          item.ActivationDate = this.formatSQLDateToHuman(item.ActivationDate);
        }
        if (item.EndDate != null) {
          item.EndDate = this.formatSQLDateToHuman(item.EndDate);
        }
      }
    },

    showPurchases() {
      this.hidden = !this.hidden;
    },

    formatSQLDateToHuman(sqlDate) {
      let year = sqlDate.substring(0, 4);
      let month = sqlDate.substring(5, 7);
      let day = sqlDate.substring(8, 10);
      let date = new Date(year, month - 1, day);
      let humanReadableDate = date.toDateString();
      return humanReadableDate.substring(4, humanReadableDate.length);
    },

    expand() {
      this.expanded = !this.expanded;
    }
  },
};
</script>

<style scoped>
.clickable {
  color: #118bee;
}

button {
  padding: 0.5rem 1rem;
  margin-bottom: 20px;
}

img {
  height: 32px;
  width: 32px;
}
</style>
