<template>
  <div class="cart-row">
    <div class="cart-item cart-column">
      <img class="cart-item-image" :src="`/Images/${item.item.Nombre}.png`" width="100" height="100" />
      <span class="cart-item-title">{{ item.item.Nombre }}</span>
      <span class="cart-item-title">
      {{ item.item.users.user1 }}
      <br> {{ item.item.users.user2 }}
      <br> {{ item.item.users.user3 }}
      <br> {{ item.item.users.user4 }}
      <br> {{ item.item.users.user5 }}
      <br> {{ item.item.users.user6 }}
      <br> {{ item.item.users.user7 }}
      <br> {{ item.item.users.user8 }}
      </span>
    </div>
    <span class="cart-price cart-column">{{ item.item.Coste / 100 }}</span>
    <div class="cart-quantity cart-column">
      <!-- <input class="cart-quantity-input" type="number" v-model="item.quantity" :min="1" /> -->
      <button @click="remove" class="btn btn-danger" type="button">REMOVE</button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["item"],
  methods: {
    remove() {
      this.$store.commit("removeFromCart", this.item.item);
    },
  },
};
</script>

<style scoped>
.cart-header {
  font-weight: bold;
  font-size: 1.25em;
  color: white;
}

.cart-column {
  display: flex;
  align-items: center;
  border-bottom: 1px solid black;
  margin-right: 1.5em;
  padding-bottom: 10px;
  margin-top: 10px;
}

.cart-row {
  display: flex;
}

.cart-item {
  width: 45%;
}

.cart-price {
  width: 20%;
  font-size: 1.2em;
  color: white;
}

/* .cart-quantity {
  width: 35%;
} */

.cart-item-title {
  color: white;
  margin-left: 0.5em;
  font-size: 1.2em;
}

.cart-item-image {
  width: 75px;
  height: auto;
  border-radius: 10px;
}

/* .cart-quantity-input {
  height: 34px;
  width: 50px;
  border-radius: 5px;
  border: 1px solid #56ccf2;
  background-color: #eee;
  color: #333;
  padding: 0;
  text-align: center;
  font-size: 1.2em;
  margin-right: 25px;
} */

.cart-row:last-child {
  border-bottom: 1px solid black;
}

.cart-row:last-child .cart-column {
  border: none;
}

.cart-total {
  text-align: end;
  margin-top: 10px;
  margin-right: 10px;
}

.cart-total-title {
  font-weight: bold;
  font-size: 1.5em;
  color: black;
  margin-right: 20px;
}

.cart-total-price {
  color: white;
  font-size: 1.1em;
}
</style>
