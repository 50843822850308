<template>
  <div class="content">
    <form id="login">
      <label>Email</label>
      <input type="text" v-model="email" />
      <button @click.prevent="resetpassword">Reset Password</button>
      <label class="feedback">{{ feedback }}</label>
      <router-link :to="{ name: 'login' }"> Back </router-link>
    </form>
  </div>
</template>

<script>
import firebase from "firebase/app";
require("firebase/auth");

export default {
  name: "App",
  data() {
    return {
      email: null,
      password: null,
      feedback: null,
    };
  },

  methods: {
    async resetpassword() {
      this.feedback = "";
      if (this.email.length < 4) {
        this.feedback = "Please provide a vaild email";
        return;
      }
      try {
        await firebase.auth().sendPasswordResetEmail(this.email);
        alert("Password reset email sent! Please look at your email to set a new password.");
        this.$router.push({ name: "login" });
      } catch (err) {
        this.feedback = err;
      }
    },
  },
};
</script>

<style scoped></style>
