<template>
  <div class="logout">
    <div class="horizontal">
      <a :href="this.webAddress"><img class="warhallHomeButton" src="/Images/LogoAndWarhall.png" /></a>
      <router-link :to="{ name: 'store' }" class="center" v-if="user"> Store </router-link>
      <div class="logged">
        <div>
          <router-link :to="{ name: 'userhome' }" v-if="user"> {{ user }} </router-link>
        </div>
        <button @click="logout" v-if="user">Log Out</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    async logout() {
      await fetch(`/sessionLogout`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      this.$store.commit("setLogin", null);
      this.$store.commit("setID", null);
      this.$store.commit("setUsername", "");
      this.$store.commit("setPro", false);
      this.$store.commit("setOwnedDLCS", []);
      this.$store.commit("setProStatus", null);
      this.$router.push("/");
    },
  },

  computed: {
    user() {
      return this.$store.state.username;
    },

    webAddress() {
      return this.$store.state.webAddress
    }
  },
};
</script>

<style scoped>

.logged {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
}

.logout {
  background-color: #1b262b;
  color: white;
  padding: 0.25em 0;
  width: 100%;
  height: 60px;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 5;
}

button {
  padding: 2px;
}

.line {
  height: 30px;
  line-height: 30px;
}

.center {
  margin: 0 auto;
}
</style>
