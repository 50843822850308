<template>
  <div>
    <div class="shop-item">
      <span class="shop-item-title">{{ item.Nombre }}</span>
      <img class="shop-item-image" :src="`/Images/${item.Nombre}.png`" />
      <div class="shop-item-details" v-show="show">
        <span class="shop-item-price">{{ item.Coste / 100 }} €</span>
        <button v-on:click="show" v-show="!description">Buy Now</button>

        <!-- <a v-on:click="show" v-show="!description" href="#focus">Buy Now</a> -->
      </div>
      <div v-show="description">
        <itemDescription :item="item" @show="show" />
      </div>
      <div ref="scrollToMe"> </div>
    </div>
  </div>
</template>

<script>
import ItemDescription from "@/components/userhome/itemdescription.vue";

export default {
  data() {
    return {
      description: false,
    };
  },

  components: {
    itemDescription: ItemDescription,
  },

  methods: {
    scrollToElement() {
      const el = this.$refs.scrollToMe;

      if (el) {
        el.scrollTop = el.scrollHeight;
        // Use el.scrollIntoView() to instantly scroll to the element
        el.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    },

    show() {
      this.description = !this.description;
      // This hides other item Description Components that might be open.
      // We just want one openDescription Component at the time
      if (this.$store.state.openDescription != null && this.$store.state.openDescription != this) {
        this.$store.state.openDescription.hide();
      }
      if (this.description) {
        this.$store.commit("setOpenDescription", this);
        this.scrollToElement();
      }
    },

    hide() {
      this.description = false;
    },
  },

  props: ["item"],
};
</script>

<style scoped>
.shop-item {
  margin: 30px;
  min-width: 300px;
}

.shop-item-title {
  display: block;
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 1.5em;
  color: white;
  margin-bottom: 15px;
}

.shop-item-image {
  height: 250px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.shop-item-details {
  display: flex;
  align-items: center;
  padding: 5px;
}

.shop-item-price {
  flex-grow: 1;
  color: white;
}
</style>
