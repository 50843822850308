import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export function isInCart(state, elt) {
  for (let item of state.cart) {
    if (item.item.ID == elt.ID) {
      return true;
    }
  }
  return false;
}

export default new Vuex.Store({
  state: {
    email: null,
    username: "",
    userid: null,
    server: "http://localhost:3000",
    pro: false,
    ownedDLCS: [],
    proStatus: null,
    appPadding: true,
    cart: [],
    openDescription: null,
    serverAddress: "",
    dataAddress: "",
    webAddress: "",
  },
  mutations: {
    setID(state, val) {
      state.userid = val;
    },

    setLogin(state, val) {
      state.email = val;
    },

    setUsername(state, val) {
      state.username = val;
    },

    setPro(state, val) {
      state.pro = val;
    },

    setOwnedDLCS(state, val) {
      state.ownedDLCS = val;
    },

    setProStatus(state, val) {
      state.proStatus = val;
    },

    setOpenDescription(state, val) {
      state.openDescription = val;
    },

    setServerAddress(state, val) {
      state.serverAddress = val;
    },

    setDataAddress(state, val) {
      state.dataAddress = val;
    },

    setWebAddress(state, val) {
      state.webAddress = val;
    },

    addToCart(state, elt) {
      if (isInCart(state, elt)) {
        alert("This item is already added to the cart");
        return;
      }
      state.cart.push({ quantity: 1, item: elt });
    },

    removeFromCart(state, item) {
      let index = state.cart.findIndex((elt) => item.ID == elt.item.ID);
      if (index >= 0) {
        state.cart.splice(index, 1);
      }
    },
  },

  getters: {
    isInCart(state, elt) {
      return isInCart(state, elt);
    },
  },
  actions: {},
  modules: {},
});
